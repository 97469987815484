<template>
  <b-row
    class="full-height"
  >
    <b-col
      lg="6"
      class="p-5"
    >
      <div class="logo">
        <b-img
          :src="require('@/assets/images/logo/muslim-do-logo.png')"
          class="logo-img"
          alt="logo"
          height="100em"
        />

        <div class="text-center text-black">
          <p>Login with your data you entered <br> during your registration</p>
        </div>
      </div>

      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(login)">
          <text-input
            id="username-email"
            name="Username/Email"
            rules="required"
            :text.sync="user.username"
          />

          <div class="password-container">
            <text-input
              id="password"
              name="Password"
              rules="required"
              :type="passwordFieldType"
              :text.sync="user.password"
            />

            <feather-icon
              :icon="passwordToggleIcon"
              size="25"
              class="toggle-password"
              @click="togglePasswordVisibility()"
            />
          </div>

          <div
            class="text-right"
          >
            <b-link
              :to="{name:'reset-password-request'}"
            >
              Forget Password
            </b-link>
          </div>

          <b-button
            type="submit"
            variant="primary"
            class="mt-2 btn-block"
          >
            Login
          </b-button>
        </b-form>
      </validation-observer>
    </b-col>

    <b-col
      lg="6"
      class="carousel p-5 d-none d-lg-block"
    >
      <div class="text-center">
        <p class="display-4 text-light">
          Signup
        </p>
        <b-row align-h="center">
          <b-col
            md="5"
            class="register-card mr-1"
          >
            <b-link :to="{name:'organization-users-signup'}">
              <strong>Has an organization</strong>
              <p class="mt-1">
                join us to manage your org
              </p>
            </b-link>
          </b-col>
          <b-col
            md="5"
            class="register-card"
          >
            <b-link :to="{name:'individual-users-signup'}">
              <strong>An individual business</strong>
              <p class="mt-1">
                join us to manage your own business
              </p>
            </b-link>
          </b-col>
        </b-row>
        <b-link
          :to="{name:'register-read-more'}"
          class="read-more"
        >
          Read more &gt;&gt;
        </b-link>
      </div>

      <!-- Carousel -->
      <carousel :images="images" />
    </b-col>
  </b-row>
</template>

<script>
/*  eslint-disable global-require */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import Carousel from '@/common/components/Announcements/CustomCarousel.vue'
import { requestPermission } from '../../firebase'

export default {
  name: 'Login',
  components: {
    TextInput,
    Carousel,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    images() {
      return [
        require('@/assets/images/slides/business.svg'),
        require('@/assets/images/slides/orgs.svg'),
        require('@/assets/images/slides/super-admin.svg'),
      ]
    },
  },
  methods: {
    login() {
      this.$store.dispatch('auth/login', this.user)
        .then(() => {
          requestPermission()
          this.redirectToHomePage()
        }).catch(err => {
          this.$swal(
            'Invalid',
            err.response.data.errors.error ?? 'Invalid Credentials',
            'warning',
          )
        })
    },
    redirectToHomePage() {
      this.$router.push(getHomeRouteForLoggedInUser())
    },
  },
}
</script>

<style lang="scss">

.carousel{
  background-color: #24BDC6;
}

.logo {
    text-align-last: center;
    margin: 5rem 0;
}

.sign-up a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

.sign-up {
    font-size: 16px;
    color: black !important;
    font-weight: 500;
}

.full-height{
  height: 100vh !important;
}

.password-container {
  position: relative;

  .toggle-password {
    position: absolute;
    top:2.3em;
    right: -2.6em;
  }
}

.register-card {
  background-color: #fff;
  padding: 1em .5em;
  border-radius: 10px;
  border:1px solid #000;
  margin-bottom: 1em;
}
.read-more {
  color: #000;
  text-decoration: underline;
}
</style>
